import styled from "@emotion/styled"
import React from "react"
import { RawFieldLabel } from "../../components/FieldLabel"
import { medias } from "../../../css/media"

export const ProfileContainer = styled.section`
    display: flex;

    flex-direction: column;
    @media (${medias.tablet}) {
        flex-direction: row;
    }
`

export const ProfileBoard = styled.section`
    margin: 0 auto;
    padding-bottom: 1rem;
    max-width: 90%;
    order: 1;
    @media (${medias.tablet}) {
        order: 0;
        flex: 1 1 auto;
        max-width: 60%;
    }
`

export const ProfileDetails = styled.article`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-right: 10%;
    padding: 1rem;
    font-size: 1rem;
    order: 0;
    width: 90%;
    @media (${medias.tablet}) {
        order: 1;
        flex: 0 0 auto;
        width: 24%;
    }
`

export const ProfileDetailsList = styled.dl`
    width: 100%;
`

export const ProfileAvatar = ({ onSave, ...props }) => (
    <AvatarLabel htmlFor="avatar">
        <ProfileAvatarI {...props} />
        <input
            type="file"
            accept="image/*"
            id="avatar"
            style={{ display: "none" }}
            onChange={(e) => {
                const file = e.target.files[0]

                onSave && onSave(file)
            }}
        />
    </AvatarLabel>
)
const AvatarLabel = styled(RawFieldLabel)`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`

const ProfileAvatarI = styled.img`
    display: block;
    width: 6rem;
    height: 6rem;
    border: 1px solid black;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
`
