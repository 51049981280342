import React, { useMemo } from "react"
import styled from "@emotion/styled"

export const Pagination = ({ count, start = 1, current, onSelect, ...props }) => {
    const range = useMemo(
        () =>
            Array(count)
                .fill(0)
                .map((_, i) => start + i),
        [count, start]
    )

    return (
        <Wrapper {...props}>
            {count > 0 ? (
                <>
                    Page
                    {range.map((v) => {
                        const focused = v === current
                        return (
                            <Item focused={focused} onClick={() => !focused && onSelect?.(v)}>
                                {v}
                            </Item>
                        )
                    })}
                </>
            ) : (
                "Pas de résultat"
            )}
        </Wrapper>
    )
}
const Wrapper = styled.div`
    font-size: 0.7rem;
    margin: 0 auto;
`

const Item = styled.button`
    display: inline-block;
    margin: 0 0 0 0.2rem;
    padding: 0;
    color: ${(props) => (props.focused ? "#e66f47" : "black")};
    cursor: ${(props) => (props.focused ? "none" : "pointer")};
    font-size: 0.7rem;

    border: none;
    background: none;

    &:hover {
        color: #e66f47;
    }
`
