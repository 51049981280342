import styled from "@emotion/styled"
import React from "react"

export const FieldLabel = ({ htmlFor, onClick, ...props }) => {
    return (
        <RawFieldLabel
            {...props}
            onClick={(e) => {
                e.preventDefault()
                const $target = document.getElementById(htmlFor)
                if ($target && $target.focus) {
                    placeCaretAtEnd($target)
                }
                onClick && onClick(e)
            }}
        />
    )
}

export const RawFieldLabel = styled.label`
    display: ${(props) => (props.hidden ? "none" : "block")};
    font-size: 1rem;
    font-weight: bold;
    position: relative;
    cursor: pointer;

    &:hover:after {
        display: block;
        content: "✎";
        background-color: #e66f47;
        color: white;
        border-radius: 50%;
        text-align: center;
        font-size: 0.8em;
        line-height: 1em;
        width: 1em;
        height: 1em;
        padding: 0.2em;
        position: absolute;
        right: 0;
        top: 0;
    }
`
function placeCaretAtEnd(el) {
    el.focus()
    if (typeof window.getSelection !== "undefined" && typeof document.createRange !== "undefined") {
        const range = document.createRange()
        range.selectNodeContents(el)
        range.collapse(false)
        const sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange(range)
    } else if (typeof document.body.createTextRange !== "undefined") {
        const textRange = document.body.createTextRange()
        textRange.moveToElementText(el)
        textRange.collapse(false)
        textRange.select()
    }
}
