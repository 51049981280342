import styled from "@emotion/styled"
import Link from "gatsby-link"

export const OrangeLink = styled(Link)`
    border: none;
    font-size: 0.7rem;
    text-decoration: none;
    padding: 0.5rem 1.3rem;
    color: white;
    background-color: #e66f47;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        background-color: #b55037;
    }
    &:disabled {
        touch-action: none;
        filter: grayscale(0.6);
        cursor: auto;
    }
`
export const OrangeExternalLink = OrangeLink.withComponent("a")
export const OrangeButton = OrangeLink.withComponent("button")

export const InvertedOrangeLink = styled(OrangeLink)`
    border: 1px solid #e66f47;
    color: #e66f47;
    background-color: white;

    &:hover {
        background-color: #e66f47;
        color: white;
    }
`

export const InvertedOrangeButton = InvertedOrangeLink.withComponent("button")
