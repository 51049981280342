export const subjects = [
    {
        name: "Math",
        icon: "https://www.flaticon.com/svg/static/icons/svg/2891/2891382.svg"
    },
    {
        name: "Langue",
        icon: "https://www.flaticon.com/svg/static/icons/svg/1373/1373419.svg"
    },
    {
        name: "Géographie",
        icon: "https://www.flaticon.com/svg/static/icons/svg/921/921439.svg"
    },
    {
        name: "Histoire",
        icon: "https://www.flaticon.com/svg/static/icons/svg/1010/1010122.svg"
    }
]
