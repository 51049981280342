/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "@reach/router"
import { firestore, queryResultToArray } from "../../firebase"
import { missionsIndex } from "../algolia/algolia"
import styled from "@emotion/styled"
import { subjects } from "../enums/Subjects"
import { FiMapPin } from "react-icons/fi"
import { FaGraduationCap, FaHourglassEnd, FaSearch } from "react-icons/fa"
import { GrPowerCycle } from "react-icons/gr"
import { SectionTitle } from "../../components/SectionTitle"
import { ProfileBoard, ProfileContainer, ProfileDetails } from "../profile/components/ProfilteContainer"
import { Pagination } from "../components/Pagination"
import swal from "@sweetalert/with-react"
import { useProfile } from "../auth/auth.hooks"
import { VOLUNTEER } from "../profile/profiles.types"
import { medias } from "../../css/media"
import { MissionStatus } from "../../enums/MissionStatus"

const PageItemCount = 10
export const MissionSearch = () => {
    const { user, account } = useProfile()
    const navigate = useNavigate()
    const location = useLocation()
    const currentSearch = new URLSearchParams(location.search).get("q")
    const [previousQ, setPreviousQ] = useState(currentSearch)
    const [page, setPage] = useState(0)
    useEffect(() => {
        if (previousQ !== currentSearch) {
            setPreviousQ(currentSearch)
            setPage(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSearch])
    const urgent = useUrgentMissions()
    const { total, missions } = useSearch(currentSearch, page)

    return (
        <ProfileContainer>
            <ProfileBoard>
                <SectionTitle>Recherche de missions</SectionTitle>
                <SearchBar replace={true} currentSearch={currentSearch} />
                {missions.map((v) => {
                    return (
                        <SearchLine onClick={onMissionClick(v, account, user, navigate)}>
                            <SearchIconContainer src={subjects.find(({ name }) => name === v.subject)?.icon} />
                            <SearchTitle>{v.subject}</SearchTitle>
                            <SearchInfoBar>
                                <SearchDetails>
                                    <FiMapPin size="1rem" />
                                    <span>&nbsp;{v.location || "À préciser"}</span>
                                </SearchDetails>
                                <SearchDetails>
                                    <FaGraduationCap size="1rem" />
                                    <span>&nbsp;{v.studentLevel || "À préciser"}</span>
                                </SearchDetails>
                                <SearchDetails>
                                    <FaHourglassEnd size="1rem" />
                                    <span>&nbsp;{v.seance || "À préciser"}</span>
                                </SearchDetails>
                                <SearchDetails>
                                    <GrPowerCycle size="1rem" />
                                    <span>&nbsp;{v.rates || "À préciser"}</span>
                                </SearchDetails>
                            </SearchInfoBar>
                            <p>{v.description}</p>
                        </SearchLine>
                    )
                })}
                <Pagination count={Math.ceil(total / PageItemCount)} current={page} onSelect={setPage} />
            </ProfileBoard>
            <ProfileDetails>
                <UrgentContainer>
                    {urgent.map((v) => (
                        <UrgentCart mission={v} />
                    ))}
                </UrgentContainer>
            </ProfileDetails>
        </ProfileContainer>
    )
}

const useSearch = (q, page) => {
    const [pageContent, setPageContent] = useState([])

    useEffect(() => {
        if (q) return

        firestore
            .collection("missions")
            .where("status", "==", MissionStatus.validated)
            .orderBy("startAt", "asc")
            .get()
            .then((s) => {
                console.log(s)
                setPageContent(queryResultToArray(s))
            })
    }, [q, page])

    useEffect(() => {
        if (!q) return
        missionsIndex
            .search(q, {
                hitsPerPage: 100
            })
            .then((response) => {
                console.log(response)
                setPageContent([...response.hits])
            })
            .catch((e) => {
                console.error("failed", e)
            })
    }, [q])
    const missions = useMemo(() => {
        return pageContent.slice(page * PageItemCount, (page + 1) * PageItemCount)
    }, [pageContent, page])
    console.log(pageContent, missions, page)
    return { missions, total: pageContent.length }
}
export const useUrgentMissions = () => {
    if (typeof window === "undefined") return []
    const [urgent, setUrgent] = useState([])

    useEffect(() => {
        firestore
            .collection("missions")
            .where("status", "==", MissionStatus.validated)
            .where("startAt", "<=", new Date().toISOString())
            .orderBy("startAt", "desc")
            .limit(3)
            .get()
            .then((s) => {
                console.log(s)
                setUrgent(queryResultToArray(s))
            })
    }, [])

    return urgent
}
const UrgentContainer = styled.div`
    display: flex;
    overflow-x: scroll;
    max-width: 100%;

    @media (${medias.tablet}) {
        flex-direction: column;
        overflow-x: auto;
    }
`

const SearchLine = styled.article`
    background-color: #edf3f5;
    border-radius: 10px;
    margin: 1rem;
    padding: 1rem;
    position: relative;
    font-size: 1rem;
    box-shadow: 1px 1px 2px black;
    transition: all 150ms ease-in-out;
    cursor: pointer;

    @media (${medias.tablet}) {
        box-shadow: 0 0 0 black;
    }

    &:hover {
        box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.7);
        transform: translate(-2px, -2px);
    }
`

const SearchTitle = styled.h1`
    font-size: 1.5em;
    margin: 0 0 1em;
`

const SearchIconContainer = styled.img`
    position: absolute;
    top: 1em;
    right: 1em;
    height: 2.5em;
    width: auto;
`

const SearchInfoBar = styled.div`
    display: flex;
    flex-wrap: wrap;
`
const SearchDetails = styled.div`
    flex: 1 0 25%;
    line-height: 0.5em;
    font-weight: 500;
    font-size: 0.8em;
    margin-bottom: 0.5em;
    margin-right: 0.3rem;
    white-space: nowrap;

    & > span {
        vertical-align: text-top;
    }
`
const UrgentCard = styled(SearchLine)`
    background-color: white;
    font-size: 0.6rem;
    padding: 1.2rem 0.5rem 1rem;

    &:before {
        position: absolute;
        top: 0.3rem;
        left: -0.25rem;
        padding: 0.1rem 0.3rem;
        font-weight: bold;
        text-transform: uppercase;
        color: white;
        font-size: 0.5rem;
        background-color: #ff7714;
        content: "Urgent";
    }
`

const SearchForm = styled.form`
    display: flex;
    width: 80%;
    align-items: stretch;
    margin: 0 auto;
`
const SearchInput = styled.input`
    flex: 1 1 auto;
    background-color: #edf3f5;
    border: 1px solid black;
    padding: 0.8rem;
`
const SearchButton = styled.button`
    flex: 0 0 auto;
    border-radius: 0;
    border: 1px solid black;
    border-left: none;
`

function onMissionClick(mission, account, user, navigate) {
    return async () => {
        const aldready = user && mission.applicants.includes(user.uid)
        const wannaContact = await swal({
            content: (
                <div>
                    <SearchIconContainer src={subjects.find(({ name }) => name === mission.subject)?.icon} />
                    <SearchTitle>{mission.subject}</SearchTitle>
                    <SearchInfoBar>
                        <SearchDetails>
                            <FiMapPin size="1rem" />
                            <span>&nbsp;{mission.location || "À préciser"}</span>
                        </SearchDetails>
                        <SearchDetails>
                            <FaGraduationCap size="1rem" />
                            <span>&nbsp;{mission.studentLevel || "À préciser"}</span>
                        </SearchDetails>
                        <SearchDetails>
                            <FaHourglassEnd size="1rem" />
                            <span>&nbsp;{mission.seance || "À préciser"}</span>
                        </SearchDetails>
                        <SearchDetails>
                            <GrPowerCycle size="1rem" />
                            <span>&nbsp;{mission.rates || "À préciser"}</span>
                        </SearchDetails>
                    </SearchInfoBar>
                    <p>{mission.description}</p>
                    {aldready ? <p>Tu as déjà postulé à cette mission.</p> : <p>Intéressé(e) ? Tu peux postuler à la mission directement. L'association prendra contact avec toi pour convenir d'un rendez-vous.</p>}
                </div>
            ),
            ...(aldready
                ? { buttons: false }
                : {
                      button: "Postuler"
                  })
        })

        if (!wannaContact) {
            return
        }
        if (account && account.type === VOLUNTEER) {
            await firestore.runTransaction((transcation) => {
                const ref = firestore.collection("missions").doc(mission.id)

                return transcation.get(ref).then((snap) => {
                    return transcation.update(ref, "applicants", (mission.applicants = [...snap.data().applicants, user.uid]))
                })
            })
            await swal({
                icon: "success",
                content: (
                    <p>
                        <br />
                        <br />
                        Vous serez contacté directement par l'association.
                    </p>
                )
            })
        } else {
            const wannaSub = await swal({
                content: <p>Vous devez avoir un compte de bénévole pour contacter l'association.</p>,
                button: "M'inscrire"
            })

            if (!wannaSub) {
                return
            }
            navigate("/app/signup")
        }
    }
}

export const SearchBar = ({ replace = false, currentSearch = "", placeholder = "Trouver une mission par ville, matières ..." }) => {
    const navigate = useNavigate()
    return (
        <SearchForm
            onSubmit={(e) => {
                e.preventDefault()
                const value = e.target.search.value
                navigate("/app/search?q=" + value, { replace: replace })
            }}
        >
            <SearchInput type="text" name="search" id="search" defaultValue={currentSearch} placeholder={placeholder} />
            <SearchButton type="submit">
                <FaSearch size="1.5rem" />
            </SearchButton>
        </SearchForm>
    )
}

export const UrgentCart = ({ mission }) => {
    const { user, account } = useProfile()
    const navigate = useNavigate()

    return (
        <UrgentCard onClick={onMissionClick(mission, account, user, navigate)}>
            <SearchIconContainer src={subjects.find(({ name }) => name === mission.subject)?.icon} />
            <SearchTitle>{mission.subject}</SearchTitle>
            <SearchInfoBar>
                <SearchDetails>
                    <FiMapPin size="1rem" />
                    <span>&nbsp;{mission.location || "À préciser"}</span>
                </SearchDetails>
                <SearchDetails>
                    <FaGraduationCap size="1rem" />
                    <span>&nbsp;{mission.studentLevel || "À préciser"}</span>
                </SearchDetails>
                <SearchDetails>
                    <FaHourglassEnd size="1rem" />
                    <span>&nbsp;{mission.seance || "À préciser"}</span>
                </SearchDetails>
                <SearchDetails>
                    <GrPowerCycle size="1rem" />
                    <span>&nbsp;{mission.rates || "À préciser"}</span>
                </SearchDetails>
            </SearchInfoBar>
            <p>{mission.description}</p>
        </UrgentCard>
    )
}
